import { goto } from "$app/navigation"
import { resume$ } from "$lib/settings/layout"
import { App, type URLOpenListenerEvent } from "@capacitor/app"

import { dev } from "$app/environment"
import * as env from "$env/static/public"
import * as Sentry from "@sentry/sveltekit"

if (!dev) {
    // @feat: Sentry
    // If you don't want to use Session Replay, remove the `Replay` integration,
    // `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
    Sentry.init({
        dsn: "https://496a8c1e87754c286ce70585d1c151a1@o4506706214453248.ingest.sentry.io/4506706241519616",
        tracesSampleRate: 1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1,
        integrations: [Sentry.replayIntegration()],
        environment: env.PUBLIC_SENTRY_ENVIRONMENT,
    })
}
export const handleError = Sentry.handleErrorWithSentry()

/* ============================================================================================== */
/*         No need for `if (browser)` checks, because we are in *only client hooks* file.         */
/* ============================================================================================== */

// @feat: deeplinks
console.log("[Capacitor] register `appUrlOpen` listener")
App.addListener("appUrlOpen", function (event: URLOpenListenerEvent) {
    console.log("[Capacitor] `appUrlOpen` event url", event.url)
    // Example url: https://beerswift.app/tabs/tabs2
    // slug = /tabs/tabs2
    // Using `||` instead of `??` to map '' -> '/'
    const slug = event.url.split("group.tube").pop() || "/"

    console.log("[Capacitor] `appUrlOpen` event slug", slug)
    goto(slug)
})

App.addListener("resume", () => {
    console.log("[Capacitor] `resume`")
    resume$.next()
})

// App.addListener("pause", () => {
//     console.log("[Capacitor] `pause`")
// })

// App.addListener('appStateChange', ({ isActive }) => {
//     console.log('App state changed. Is active?', isActive)
// })

// App.addListener('appUrlOpen', data => {
//     console.log('App opened with URL:', data)
// })

// App.addListener('appRestoredResult', data => {
//     console.log('Restored state:', data)
// })

// App.addListener('backButton', data => {
//     console.log('Restored state:', data)
// })
